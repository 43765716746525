import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { promiseAllSequence } from '@/utils/promises';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';

import {
  ArtifactFormConfig,
  ArtifactFormData,
} from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';
import {
  applyFormData,
  artifactDefaultValues,
  buildFormType,
} from '@/features/artifacts/components/ArtifactForm/ArtifactForm.helpers';

import {
  AddingItemComplementarState,
  ItemComplementarState,
  ItemStatus,
  RetrievingItemState,
  TipoItemComplementar,
} from '../models';
import SolicitacoesAPI from '../services/SolicitacoesAPI';
import CondutaComplementarService from '../services/CondutaComplementarService';
import { apiToItemState } from '../helpers/parsers';
import { updateItemStateData } from '../helpers/managers';
import FormDefaults from '../models/FormDefaults';

type CondutaComplementarMode = 'on-hold' | 'active' | 'read-only';

interface CondutaComplementarState {
  generalType: TipoItemComplementar;
  isAddingItem: boolean;
  isUpdatingItem: boolean;
  updatingItems: ItemComplementarState[];
  isDeletingItem: boolean;
  isMenuOpen: boolean;
  shouldDiscardChanges: boolean;
}

interface IFormState {
  form: ArtifactFormConfig;
  data: ArtifactFormData;
}

type ICondutaForms = Partial<Record<TipoItemComplementar, IFormState>>;

interface ICondutaComplementarContext {
  mode: CondutaComplementarMode;
  globalForms: ICondutaForms;
  items: ItemComplementarState[];
  state: CondutaComplementarState;
  // UI methods
  setGeneralType: (type: TipoItemComplementar) => void;
  toggleMenu: (isOpen?: boolean) => void;
  discardChanges: () => void;
  cancelDiscardChanges: () => void;
  // Filtering methods
  getItemsByType: (type: TipoItemComplementar) => ItemComplementarState[];
  getItemById: (id: string) => ItemComplementarState | undefined;
  // Items management methods
  addItem: (
    item: AddingItemComplementarState,
  ) => Promise<ItemComplementarState | null>;
  deleteItem: (item: RetrievingItemState) => Promise<void>;
  updateItem: (item: ItemComplementarState) => Promise<ItemComplementarState>;
  updateItemState: (
    item: RetrievingItemState,
    itemState: Partial<ItemStatus>,
  ) => ItemComplementarState | undefined;
  updateItems: (
    items: ItemComplementarState[],
  ) => Promise<ItemComplementarState[]>;
  // Forms management methods
  getGlobalForm: (type: TipoItemComplementar) => ArtifactFormConfig | undefined;
  setGlobalData: (type: TipoItemComplementar, data: ArtifactFormData) => void;
  clearGlobalData: (type: TipoItemComplementar) => void;
}

export const CondutaComplementarContext = React.createContext<
  ICondutaComplementarContext | undefined
>(undefined);

type ProviderProps = React.PropsWithChildren;

const CondutaComplementarProvider: React.FC<ProviderProps> = ({ children }) => {
  const [{ protocoloExecutado, isProtocoloReadOnly }] = useExecucaoContext();
  const [items, setItems] = React.useState<ItemComplementarState[]>([]);

  const [state, setState] = React.useState<CondutaComplementarState>({
    isAddingItem: false,
    isUpdatingItem: false,
    updatingItems: [],
    isDeletingItem: false,
    isMenuOpen: false,
    shouldDiscardChanges: false,
    generalType: 'solicitacao-exame',
  });

  const [globalForms, setGlobalForms] = React.useState<ICondutaForms>(() => {
    const exameForm = buildFormType(
      'solicitacao-exame',
      FormDefaults.ExameGroup,
    );

    return {
      'solicitacao-exame': {
        form: exameForm,
        data: artifactDefaultValues({ form: exameForm }),
      },
    };
  });

  const mode: CondutaComplementarMode = React.useMemo(() => {
    if (protocoloExecutado) {
      const { status = 'ABERTO' } = protocoloExecutado || {};

      return status === 'FINALIZADO' || isProtocoloReadOnly
        ? 'read-only'
        : 'active';
    }

    return 'on-hold';
  }, [isProtocoloReadOnly, protocoloExecutado]);

  const setGeneralType = React.useCallback((type: TipoItemComplementar) => {
    setState(st => ({ ...st, generalType: type }));
  }, []);

  const shouldConfirmDiscardChanges = React.useCallback(
    (isOpen?: boolean) => {
      const isCloseRequest = state.isMenuOpen && !isOpen;
      const hasSomeEditing = items.some(item => item.itemState?.isEditing);

      return isCloseRequest && hasSomeEditing && !state.shouldDiscardChanges;
    },
    [items, state.isMenuOpen, state.shouldDiscardChanges],
  );

  const toggleMenu = React.useCallback(
    (isOpen?: boolean) => {
      if (shouldConfirmDiscardChanges(isOpen)) {
        setState(prev => ({ ...prev, shouldDiscardChanges: true }));
      } else {
        setState(({ isMenuOpen, ...rest }) => ({
          ...rest,
          isMenuOpen: isOpen === undefined ? !isMenuOpen : isOpen,
        }));
      }
    },
    [shouldConfirmDiscardChanges],
  );

  const cancelDiscardChanges = React.useCallback(() => {
    setState(prev => ({ ...prev, shouldDiscardChanges: false }));
  }, []);

  const discardChanges = React.useCallback(() => {
    setItems(prev =>
      prev
        .filter(item => !item.itemState?.isAdding)
        .map(({ itemState = {}, ...item }) => ({
          ...item,
          itemState: { ...itemState, isEditing: false },
        })),
    );

    cancelDiscardChanges();
  }, [cancelDiscardChanges]);

  const getItemsByType = React.useCallback(
    (type: TipoItemComplementar) =>
      items.filter(({ type: itemType }) => itemType === type),
    [items],
  );

  const getItemById = React.useCallback(
    (findID: string) => items.find(({ id }) => id === findID),
    [items],
  );

  const setGlobalData = React.useCallback(
    (formType: TipoItemComplementar, data: ArtifactFormData) => {
      setGlobalForms(prev => {
        const config = prev[formType];

        if (config !== undefined) {
          return {
            ...prev,
            [formType]: { ...config, data },
          };
        }

        return prev;
      });
    },
    [],
  );

  const getGlobalForm = React.useCallback(
    (type: TipoItemComplementar) => {
      const config = globalForms[type];
      if (config !== undefined) {
        return config.form;
      }

      return undefined;
    },
    [globalForms],
  );

  const clearGlobalData = React.useCallback((type: TipoItemComplementar) => {
    setGlobalForms(prev => {
      const config = prev[type];
      if (config !== undefined) {
        return {
          ...prev,
          [type]: {
            form: config.form,
            data: artifactDefaultValues({ form: config.form }),
          },
        };
      }
      return prev;
    });
  }, []);

  const updateItemState = React.useCallback(
    (
      { id: updatingID }: RetrievingItemState,
      itemState: ItemComplementarState['itemState'],
    ) => {
      let newItem: ItemComplementarState | undefined;

      setItems(prev =>
        prev.map(item => {
          if (item.id === updatingID) {
            return updateItemStateData(item, { itemState });
          }

          return item;
        }),
      );

      return newItem;
    },
    [],
  );

  const addItem = React.useCallback(
    async (item: AddingItemComplementarState) => {
      setState(prev => ({ ...prev, isAddingItem: true }));

      let addingItem: ItemComplementarState = {
        id: uuidv4(), // Gera ID local p/ item
        ...item,
      };

      const { itemState: { isAdding = false } = {} } = addingItem;

      if (protocoloExecutado && !isAdding) {
        // Adiciona os dados do formulário de grupo que já esteja submetido em
        // novos itens
        const { data, form } = globalForms[item.type] || {};
        if (form && data) {
          addingItem.resource = applyFormData(form, data, addingItem.resource);
        }

        addingItem = await CondutaComplementarService.addItem(
          protocoloExecutado,
          addingItem,
        );
      }

      setItems(prev => [...prev, addingItem as ItemComplementarState]);
      setState(prev => ({ ...prev, isAddingItem: false }));

      return addingItem;
    },
    [globalForms, protocoloExecutado],
  );

  const deleteItem = React.useCallback(
    async ({ id: deletingID }: RetrievingItemState) => {
      setState(prev => ({ ...prev, isDeletingItem: true }));

      const { itemState: { isAdding = false } = {} } =
        getItemById(deletingID) || {};

      setItems(prev => prev.filter(({ id: itemID }) => itemID !== deletingID));

      if (!isAdding) {
        await SolicitacoesAPI.destroy(deletingID);
      }

      setState(prev => ({ ...prev, isDeletingItem: false }));
    },
    [getItemById],
  );

  const batchUpdate = React.useCallback(
    async (
      group: ItemComplementarState[],
      keepGroupState?: Partial<ItemStatus>,
    ): Promise<ItemComplementarState[]> => {
      if (protocoloExecutado) {
        const results = await promiseAllSequence(group, item =>
          CondutaComplementarService.updateOrCreateItem(
            protocoloExecutado,
            item,
            keepGroupState,
          ),
        );

        setItems(prev =>
          prev.map(item => {
            const [updated] =
              results.find(
                ([
                  { id: updatedID, itemState: { reference = '' } = {} },
                  created,
                ]) =>
                  (created && reference === item.id) || updatedID === item.id,
              ) || [];

            if (updated) {
              return updated;
            }

            return item;
          }),
        );

        return results.map(([item]) => item);
      }

      return [];
    },
    [protocoloExecutado],
  );

  const updateItem = React.useCallback(
    async (item: ItemComplementarState) => {
      setState(prev => ({
        ...prev,
        isUpdatingItem: true,
        updatingItems: [item],
      }));

      updateItemState(item, { isSubmitting: true });

      const [updatedItem] = await batchUpdate([item], {
        isSubmitting: false,
        isEditing: false,
        isAdding: false,
      });

      setState(prev => ({ ...prev, isUpdatingItem: false, updatingItems: [] }));

      return updatedItem;
    },
    [batchUpdate, updateItemState],
  );

  const updateItems = React.useCallback(
    async (updatingItems: ItemComplementarState[]) => {
      setState(prev => ({ ...prev, updatingItems }));

      const updatedItems = await batchUpdate(updatingItems, {
        isEditing: false,
        isSubmitting: false,
      });

      setState(prev => ({ ...prev, updatingItems: [] }));

      return updatedItems;
    },
    [batchUpdate],
  );

  React.useEffect(() => {
    if (protocoloExecutado) {
      setItems(
        protocoloExecutado.solicitacoes_complementares.map(solicitacao =>
          apiToItemState(solicitacao),
        ),
      );
    }
  }, [protocoloExecutado]);

  return (
    <CondutaComplementarContext.Provider
      value={{
        mode,
        items,
        state,
        globalForms,
        getItemsByType,
        getItemById,
        setGeneralType,
        toggleMenu,
        setGlobalData,
        getGlobalForm,
        clearGlobalData,
        addItem,
        updateItem,
        updateItemState,
        updateItems,
        deleteItem,
        discardChanges,
        cancelDiscardChanges,
      }}
    >
      {children}
    </CondutaComplementarContext.Provider>
  );
};

export function useCondutaComplementarContext(): ICondutaComplementarContext {
  const context = React.useContext(CondutaComplementarContext);
  if (context === undefined) throw new Error('Provider not found.');

  return context;
}

export default CondutaComplementarProvider;
