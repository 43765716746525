import React from 'react';
import * as yup from 'yup';
import fhirpath from 'fhirpath';
import unset from 'lodash.unset';
import { Resolver, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ArtifactType } from '@/models/Solicitacao';
import {
  ArtifactFieldConfig,
  ArtifactFieldInfo,
  ArtifactFormConfig,
  ArtifactFormData,
  ArtifactFormFields,
  ArtifactFormTypeSettings,
  ResourceSubmitHandler,
  UseArtifactFormOptions,
  UseArtifactFormMethods,
} from './ArtifactForm.types';

import SolicitacaoExameFormConfig from '../../forms/SolicitacaoExameFormConfig';
import EncaminhamentoFormConfig from '../../forms/EncaminhamentoFormConfig';
import PrescricaoMedicaFormConfig from '../../forms/PrescricaoMedicaFormConfig';

type RType = fhir4.FhirResource;

export function artifactFormResolver(form: ArtifactFormConfig): Resolver {
  const objectSchema = yup
    .object(
      form.reduce((spec, field) => {
        let fieldSchema =
          field.type === 'integer' || field.type === 'decimal'
            ? yup
                .number()
                .transform(val => (Number.isNaN(val) ? null : val))
                .nullable()
            : yup.string();

        if (!field.readonly && field.required) {
          fieldSchema = fieldSchema.required('Este campo é obrigatório');
        }

        return {
          ...spec,
          [field.name]: fieldSchema,
        };
      }, {}),
    )
    .required();

  return yupResolver(objectSchema);
}

export function artifactDefaultValues({
  resource,
  form,
}: UseArtifactFormOptions): ArtifactFormData {
  return form.reduce<ArtifactFormData>((data, { name, path }) => {
    let defaultValue = '';

    if (resource) {
      const result = fhirpath.evaluate(resource, path);
      const valueString = result.length > 0 && result[0].toString();

      defaultValue = valueString || defaultValue;
    }

    return {
      ...data,
      [name]: defaultValue,
    };
  }, {});
}

export function applyFormData(
  form: ArtifactFormConfig,
  data: ArtifactFormData,
  resource: RType,
): RType {
  const result = form.reduce<RType>((artifact, field) => {
    const value = data[field.name];

    /**
     * Remove do objeto o elemento quando o seu valor for vazio
     *
     * TODO: Dependendo do contexto de uso no futuro, não faz sentido
     * remover o elemento do objeto aqui. Talvez seja melhor as próprias
     * configurações do campo definirem o que fazer com valores de entrada
     * vazios
     */
    const isEmptyValue = value === null || value === '' || value === undefined;
    if (isEmptyValue) {
      const obj = { ...artifact } as { [key: string]: unknown };
      unset(obj, field.element);
      return (obj as unknown) as RType;
    }

    return field.toInternalValue(artifact, value as never);
  }, resource);

  return result;
}

export function useArtifactForm({
  resource,
  form,
}: UseArtifactFormOptions): UseArtifactFormMethods {
  const handleResourceSubmit = React.useCallback(
    (onValid: ResourceSubmitHandler) => {
      return (data: ArtifactFormData) => {
        if (resource) {
          const result = applyFormData(form, data, resource);
          onValid(result);
        }
      };
    },
    [form, resource],
  );

  const { handleSubmit, reset: resetForm, ...useFormMethods } = useForm({
    resolver: artifactFormResolver(form),
    defaultValues: artifactDefaultValues({ resource, form }),
  });

  const reset: typeof resetForm = React.useCallback(
    (values, keepStateOptions) => {
      const defaults = values || artifactDefaultValues({ resource, form });
      resetForm(defaults, keepStateOptions);
    },
    [form, resetForm, resource],
  );

  return {
    ...useFormMethods,
    reset,
    handleSubmit,
    handleResourceSubmit: (handler: ResourceSubmitHandler) =>
      handleSubmit(handleResourceSubmit(handler)),
  };
}

function isValidConfigValue(value: unknown): boolean {
  // Check if the value is not null, not undefined, and not NaN

  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === 'string') {
    return value.trim() !== '';
  }

  if (typeof value === 'number') {
    return !Number.isNaN(value);
  }

  return true;
}

function buildFieldConfig(
  form: ArtifactFieldConfig,
  field: ArtifactFieldInfo,
): ArtifactFieldConfig {
  const mergedConfig: ArtifactFieldConfig = { ...form };

  Object.entries(field).forEach(([key, infoValue]) => {
    if (isValidConfigValue(infoValue)) {
      mergedConfig[key as keyof ArtifactFieldConfig] = infoValue as never;
    }
  });

  return mergedConfig;
}

const ModelFormTypeMapping: ArtifactFormTypeSettings[] = [
  {
    type: 'solicitacao-exame',
    form: SolicitacaoExameFormConfig,
  },
  {
    type: 'encaminhamento',
    form: EncaminhamentoFormConfig,
  },
  {
    type: 'prescricao-medica',
    form: PrescricaoMedicaFormConfig,
  },
];

export function getForm(formType: ArtifactType): ArtifactFormConfig {
  const formSettings = ModelFormTypeMapping.find(
    ({ type }) => type === formType,
  );

  if (formSettings) {
    return (formSettings.form as unknown) as ArtifactFormConfig;
  }

  throw new Error(
    `Não existe uma configuração de formulário disponível para o tipo "${formType}".`,
  );
}

export function buildForm(
  modelForm: ArtifactFormConfig,
  fields: ArtifactFieldInfo[],
): ArtifactFormConfig {
  return fields.reduce<ArtifactFormConfig>((form, fieldInfo) => {
    const fieldConfig = modelForm.find(field => field.name === fieldInfo.name);
    if (fieldConfig) {
      return [...form, buildFieldConfig(fieldConfig, fieldInfo)];
    }

    return form;
  }, []);
}

export function buildFormType(
  formType: ArtifactType,
  fields: ArtifactFormFields,
): ArtifactFormConfig {
  const modelForm = getForm(formType);

  return buildForm(modelForm, fields);
}
