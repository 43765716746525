import React from 'react';

import { FiPlus } from 'react-icons/fi';

import OptionIntegracao from '@/types/OptionIntegracao';

import Search from '@/components/Search';

import { FhirArtefatoType } from '@/features/enterprise/models/types';
import IntegracaoService from '@/features/enterprise/services/IntegracaoService';
import { artefatoTypeToDashed } from '@/features/execucao/helpers/integracao';
import { useExecucaoContext } from '@/features/execucao/providers/ExecucaoProvider';
import { codeableConceptDisplay } from '@/features/fhir/helpers/fhirpath';

import { ArtifactFormComponentProps } from '@/features/artifacts/components/ArtifactForm';
import { ArtifactType } from '@/models/Solicitacao';
import AddCondutaExame from '@/features/recursos/exames/components/AddCondutaExame';
import AddCondutaEncaminhamento from '@/features/recursos/encaminhamentos/components/AddCondutaEncaminhamento';
import {
  AddItemComplementarButton,
  Container,
  SearchResultContent,
  SearchResultItem,
  SearchResultLoading,
  SearchResultEmpty,
  SearchResultBox,
  PreviewItemsContainer,
  PreviewItem,
} from './styles';

interface BoxCondutaComplementarProps {
  type:
    | 'Exame'
    | 'Medicamento'
    | 'Encaminhamento'
    | 'Orientacao'
    | 'Fotoceutico';
  disabled: boolean;
  handleOptionConfirm: (option: OptionIntegracao) => void;
}

type AddCondutaComponentType = React.ComponentType<ArtifactFormComponentProps>;

const BoxCondutaComplementar: React.FC<BoxCondutaComplementarProps> = ({
  type,
  disabled,
  handleOptionConfirm,
}) => {
  const [{ protocoloExecutado }] = useExecucaoContext();

  const [loadingSearchResults, setLoadingSearchResults] = React.useState(false);
  const [loadingConfirmation, setloadingConfirmation] = React.useState(false);
  const [canSearch, setCanSearch] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [searchResultItems, setSearchResultItems] = React.useState<
    FhirArtefatoType[]
  >();
  const [previewItems, setPreviewItems] = React.useState<
    fhir4.DomainResource[]
  >([]);

  const displaySearchResultBox = React.useMemo(
    () => loadingSearchResults || searchResultItems !== undefined,
    [loadingSearchResults, searchResultItems],
  );

  const condutaID = React.useMemo(() => {
    if (protocoloExecutado && protocoloExecutado.passo_corrente) {
      return protocoloExecutado.passo_corrente.id;
    }

    return null;
  }, [protocoloExecutado]);

  const AddCondutaComponent: AddCondutaComponentType = React.useMemo(() => {
    switch (type) {
      case 'Exame':
        return AddCondutaExame;

      case 'Encaminhamento':
        return AddCondutaEncaminhamento;

      default:
        return AddCondutaExame;
    }
  }, [type]);

  const searchResultItemTitle = React.useCallback(
    (item: FhirArtefatoType) => codeableConceptDisplay(item.recurso, 'code'),
    [],
  );
  const searchResultKeyExtractor = React.useCallback(
    (item: FhirArtefatoType, position: number) =>
      `${item.recurso.id} - ${position + 1} `,
    [],
  );

  const onAddClick = React.useCallback(() => {
    setCanSearch(true);
  }, []);

  const onSearchResultItemClick = React.useCallback(
    async (item: FhirArtefatoType) => {
      setSearch('');
      setPreviewItems(prev => [...prev, item.recurso as fhir4.DomainResource]);
      setCanSearch(false);
    },
    [],
  );

  const renderSearchResultsHeader = React.useCallback(() => {
    if (loadingSearchResults) {
      return <SearchResultLoading>Buscando...</SearchResultLoading>;
    }

    return <></>;
  }, [loadingSearchResults]);

  const renderSearchResultsEmpty = React.useCallback(() => {
    if (searchResultItems !== undefined) {
      return <SearchResultEmpty>Nenhum resultado encontrado</SearchResultEmpty>;
    }

    return <></>;
  }, [searchResultItems]);

  const onConfirmItemComplementar = React.useCallback(
    async (item: fhir4.DomainResource) => {
      try {
        setloadingConfirmation(true);
        if (condutaID) {
          const result = await IntegracaoService.requestAdicionarArtefatos(
            condutaID,
            { tipo: artefatoTypeToDashed(type) as ArtifactType, pedido: item },
          );

          if (result) {
            const option = {
              key: `${result.id}`,
              title: result.titulo,
              detail: result.descricao,
              categoria: result.categoria,
              value: result.id,
              pedido: result.pedido,
              included: true,
              selected: true,
            } as OptionIntegracao;

            handleOptionConfirm(option);

            setPreviewItems([]);
            setCanSearch(false);
          }
        }
      } finally {
        setloadingConfirmation(false);
      }
    },
    [condutaID, handleOptionConfirm, type],
  );

  const getItemsComplementaresList = React.useCallback(async () => {
    if (search !== '') {
      setLoadingSearchResults(true);

      try {
        const result = await IntegracaoService.getItemsComplementaresList(
          artefatoTypeToDashed(type),
          search,
        );
        if (result) {
          setSearchResultItems(result);
        }
      } finally {
        setLoadingSearchResults(false);
      }
    }
  }, [search, type]);

  React.useEffect(() => {
    if (search === '') {
      setSearchResultItems(undefined);
    }
  }, [search]);

  React.useEffect(() => {
    getItemsComplementaresList();
  }, [getItemsComplementaresList]);

  return (
    <Container>
      {previewItems.length > 0 && (
        <PreviewItemsContainer>
          {previewItems
            .sort((a, b) => (a.id && b.id && a.id > b.id ? 1 : -1))
            .map((item, index) => (
              <PreviewItem key={`${item.id}-${index + 1}`}>
                <AddCondutaComponent
                  resource={item as fhir4.ServiceRequest}
                  onSubmit={onConfirmItemComplementar}
                  onCancel={() => setPreviewItems([])}
                  loading={loadingConfirmation}
                  disabled={disabled}
                />
              </PreviewItem>
            ))}
        </PreviewItemsContainer>
      )}

      {canSearch ? (
        <>
          <Search
            autoFocus
            placeholder={`Busque pelo código ou nome do ${type.toLowerCase()}`}
            onChange={setSearch}
            debounceTimeInMS={600}
          />
          {displaySearchResultBox && (
            <SearchResultBox
              data={searchResultItems || []}
              keyExtractor={({ item, index }) =>
                searchResultKeyExtractor(item, index)
              }
              ListContentContainerElement={SearchResultContent}
              renderItem={({ item }) => (
                <SearchResultItem
                  onClick={() => {
                    onSearchResultItemClick(item);
                  }}
                >
                  {searchResultItemTitle(item)}
                </SearchResultItem>
              )}
              ListHeaderComponent={renderSearchResultsHeader}
              ListEmptyComponent={renderSearchResultsEmpty}
            />
          )}
        </>
      ) : (
        <AddItemComplementarButton
          disabled={!!previewItems.length}
          onClick={onAddClick}
        >
          <FiPlus size={16} />
          {`Adicionar ${type.toLowerCase()} complementar`}
        </AddItemComplementarButton>
      )}
    </Container>
  );
};

export default BoxCondutaComplementar;
