import { ArtifactFormFields } from '@/features/artifacts/components/ArtifactForm/ArtifactForm.types';

const ExameFormDefault: ArtifactFormFields = [
  { name: 'orderDetail', label: 'Observação' },
];

const ExameGroupFormDefault: ArtifactFormFields = [
  { name: 'reasonCode', label: 'Indicação clínica', required: true },
];

const EcaminhamentoFormDefault: ArtifactFormFields = [
  {
    name: 'reasonCode',
    label: 'Indicação clínica',
    required: true,
    autoFocus: true,
  },
];

const MedicamentoFormDefault: ArtifactFormFields = [
  {
    name: 'dosageInstruction',
    label: 'Posologia',
    required: true,
    autoFocus: true,
  },
  { name: 'patientInstruction', label: 'Observação' },
];

const MedicamentoManipuladoFormDefault: ArtifactFormFields = [
  {
    name: 'medicationCodeableConcept',
    label: 'Nome do manipulado',
    required: true,
    autoFocus: true,
  },
  {
    name: 'additionalInstruction',
    label: 'Composição',
    required: true,
    placeholder:
      'Ex: Ácido salicílico 1% + Enxofre 2% + Sabonete líquido 100ml',
  },
  { name: 'dosageInstruction', label: 'Posologia', required: true },
  { name: 'patientInstruction', label: 'Observação' },
];

export default {
  Exame: ExameFormDefault,
  ExameGroup: ExameGroupFormDefault,
  Encaminhamento: EcaminhamentoFormDefault,
  Medicamento: MedicamentoFormDefault,
  MedicamentoManipulado: MedicamentoManipuladoFormDefault,
};
